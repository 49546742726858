/**
 * util.js文件主要写的是会经常使用到的工具类
 */

function getToken() {
	return localStorage.getItem('login.token');
}


/**
 * 同步 try catch 的进一步封装处理
 * 使用方法：
 * let [err, res] = await this.$utils.asyncTasks(Promise函数);
 * if(res) 成功
 * if(err) 失败
 */
function asyncTasks(promise) {
	return promise.then(data => {
		return [null, data];
	}).catch(err => [err]);
}


/**
 * 合并 baseURL 和相对 URL 成一个完整的 URL
 * @param {String} baseURL baseURL
 * @param {String} relativeURL 相对 URL
 * @returns {String} 返回组合后的完整 URL
 */
function combineURLs(baseURL, relativeURL) {
	return relativeURL && this.isString(relativeURL) && this.isString(baseURL) ? baseURL.replace(/\/+$/, '') + '/' +
		relativeURL.replace(/^\/+/, '') : baseURL;
}

/**
 * 深度合并对象，只支持合并两个对象，该方法不会改变原有的对象
 * @param {Object} FirstOBJ 第一个对象
 * @param {Object} SecondOBJ 第二个对象
 * @return {Object} 返回深度合并后的对象
 */
function deepMargeObject(FirstOBJ, SecondOBJ) {
	let ResultOBJ = {};
	for (let key in FirstOBJ) {
		ResultOBJ[key] = ResultOBJ[key] && ResultOBJ[key].toString() === "[object Object]" ? this.deepMargeObject(
			ResultOBJ[
				key], FirstOBJ[key]) : ResultOBJ[key] = FirstOBJ[key];
	}
	for (let key in SecondOBJ) {
		ResultOBJ[key] = ResultOBJ[key] && ResultOBJ[key].toString() === "[object Object]" ? this.deepMargeObject(
			ResultOBJ[
				key], SecondOBJ[key]) : ResultOBJ[key] = SecondOBJ[key];
	}
	return ResultOBJ;
}

/**
 * 生成指定长度的随机字符串
 * @param {Number} min 最小程度
 * @param {Number} max 最大长度 
 * @return {String} 返回生成的字符串
 */
function randomString(min, max) {
	let returnStr = "",
		range = (max ? Math.round(Math.random() * (max - min)) + min : min),
		arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k',
			'l',
			'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G',
			'H', 'I',
			'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
		];
	for (let i = 0; i < range; i++) {
		let index = Math.round(Math.random() * (arr.length - 1));
		returnStr += arr[index];
	}
	return returnStr;
}

/**
 * 格式化日期
 * 
 * @param {Date|String} date 日期或日期字符串
 * 
 */
function dateFormat(fmt, date) {

	/*
	示例：
	let date = new Date()
	dateFormat("YYYY-mm-dd HH:MM", date)
	2019-06-06 19:45`
	*/

	let ret;
	const opt = {
		"Y+": date.getFullYear().toString(), // 年
		"m+": (date.getMonth() + 1).toString(), // 月
		"d+": date.getDate().toString(), // 日
		"H+": date.getHours().toString(), // 时
		"M+": date.getMinutes().toString(), // 分
		"S+": date.getSeconds().toString() // 秒
		// 有其他格式化字符需求可以继续添加，必须转化成字符串
	};
	for (let k in opt) {
		ret = new RegExp("(" + k + ")").exec(fmt);
		if (ret) {
			fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
		};
	};
	return fmt;
}

/**
 * 数字转中文
 * @param {Number} num 数字
 */
function numberToChinese(num) {
	if (!/^\d*(\.\d*)?$/.test(num)) return "Number is wrong!";
	let AA = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九");
	let BB = new Array("", "十", "百", "千", "万", "亿", "点", "");
	let a = ("" + num).replace(/(^0*)/g, "").split("."),
		k = 0,
		re = "";
	for (let i = a[0].length - 1; i >= 0; i--) {
		switch (k) {
			case 0:
				re = BB[7] + re;
				break;
			case 4:
				if (!new RegExp("0{4}\\d{" + (a[0].length - i - 1) + "}$").test(a[0]))
					re = BB[4] + re;
				break;
			case 8:
				re = BB[5] + re;
				BB[7] = BB[5];
				k = 0;
				break;
		}
		if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) re = AA[0] + re;
		if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
		k++;
	}
	if (a.length > 1) //加上小数部分(如果有小数部分) 
	{
		re += BB[6];
		for (let i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
	}
	return re;
}


/**
 * fn：检测图片协议，主要用于检测海报图片协议。
 * param(imgPath): 图片地址。
 */

function checkImgHttp(imgPath) {
	let newPath = '';
	if (imgPath.indexOf('data:image/svg+xml') !== -1) {
		newPath = '/static/imgs/base_avatar.png'
	} else {
		let pathArr = imgPath.split('://');
		// #ifdef H5
		let ishttps = 'https:' == window.location.protocol ? true : false;
		ishttps ? (pathArr[0] = 'https') : (pathArr[0] = 'http');
		// #endif
		newPath = pathArr.join('://');
	}
	return newPath;
}

/**
 * 日期格式转换 年月日时分秒
 */
function dateTime(date) {
	var dateTime = new Date(date);
	var y = dateTime.getFullYear();
	var M = dateTime.getMonth() + 1;
	var d = dateTime.getDate();
	var h = dateTime.getHours();
	var m = dateTime.getMinutes();
	if (M < 10) {
		M = "0" + M;
	}
	if (d < 10) {
		d = "0" + d;
	}
	if (h < 10) {
		h = "0" + h;
	}
	if (m < 10) {
		m = "0" + m;
	}
	dateTime = (y + "-" + M + "-" + d + "T" + h + ":" + m).toString();
	return dateTime
}

// 获取日期
function getDay(day) {
	var today = new Date();
	var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
	today.setTime(targetday_milliseconds); //注意，这行是关键代码
	var tYear = today.getFullYear();
	var tMonth = today.getMonth();
	var tDate = today.getDate();
	tMonth = doHandleMonth(tMonth + 1);
	tDate = doHandleMonth(tDate);
	return tYear + "-" + tMonth + "-" + tDate;
}

function doHandleMonth(month) {
	var m = month;
	if (month.toString().length == 1) {
		m = "0" + month;
	}
	return m;
}

// 转换日期为年月日
function changDate(date) {
	var dateTime = new Date(date);
	var y = dateTime.getFullYear();
	var M = dateTime.getMonth() + 1;
	var d = dateTime.getDate();
	if (M < 10) {
		M = "0" + M;
	}
	if (d < 10) {
		d = "0" + d;
	}
	dateTime = (y + "-" + M + "-" + d).toString();
	return dateTime
}



/**
 * 根据日期和随机数获取Id
 */
function getTimeId() {
	var d = new Date(),
		str = '';
	str += d.getFullYear(); //获取当前年份
	if (d.getMonth() < 9) {
		str += "0" + (d.getMonth() + 1); //获取当前月份（0——11）
	} else {
		str += d.getMonth() + 1; //获取当前月份（0——11）
	}
	if (d.getDate() <= 9) {
		str += "0" + d.getDate();
	} else {
		str += d.getDate();
	}
	if (d.getHours() <= 9) {
		str += "0" + d.getHours();
	} else {
		str += d.getHours();
	}
	if (d.getMinutes() <= 9) {
		str += "0" + d.getMinutes();
	} else {
		str += d.getMinutes();
	}
	if (d.getSeconds() <= 9) {
		str += "0" + d.getSeconds();
	} else {
		str += d.getSeconds();
	}

	str = str + parseInt(Math.random() * (99999 - 10000 + 1) + 10000, 10);
	return str;
}

/**
 * 是否是在微信环境中
 */
function isWeixin() {

	//#ifndef H5
	return false; //如果不是在H5环境，则不是微信中
	// #endif

	// #ifdef H5  

	//如果是在H5环境中，则检查是否是在微信里面
	let ua = navigator.userAgent.toLowerCase();
	return (ua.match(/MicroMessenger/i) == "micromessenger");
	// #endif
}

/**
 * 价格保留两位小数
 * @param {Object} price 价格
 */
function priceForTwo(price) {
	return (parseFloat(price * 100) / 100).toFixed(2)
}

/**
 * 隐藏电话号码
 * @param {Object} phone 电话
 */
function hidePhone(phone) {
	let str = phone;
	let pre = str.substr(0, 3);
	let next = str.substr(7, 4);
	return pre + '****' + next;
}

/**
 * string转数组
 * @param {*} val 
 * @returns 
 */
function stringToArray(val) {
  if (val && typeof (val) === 'string') {
    let str = val.replace(/\"/g, "'")
    str = eval(str)
    return str
  } else {
    return val
  }
}


module.exports = {
	asyncTasks: asyncTasks,
	combineURLs: combineURLs,
	deepMargeObject: deepMargeObject,
	randomString: randomString,
	dateFormat: dateFormat,
	numberToChinese: numberToChinese,
	checkImgHttp: checkImgHttp,
	getTimeId: getTimeId,
	isWeixin: isWeixin,
	dateTime: dateTime,
	priceForTwo: priceForTwo,
	hidePhone: hidePhone,
	getToken: getToken,
	changDate: changDate,
	getDay: getDay,
	stringToArray: stringToArray
}

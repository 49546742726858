import axios from "axios"
import qs from 'qs'
import { Toast } from 'vant';

// 接口地址
const apiUrl = 'https://jijiuapi.ran-wei.net';

export default function request(url, options = {}, isUpload = false, noLoad = false) {
  const method = options.method || 'GET';
  let data;
  if (process.env.NODE_ENV === 'development') {
    // 开发环境
    url = '/api' + url;
  } else {
    url = apiUrl + url;
  }

  url += (url.indexOf('?') > 0) ? "&" : "?";
	if (method != 'GET') {
		//POST 方式的请求	
		options.data.token = localStorage.getItem('login.token');
		options.data.source = 'pad';
	} else {
		//GET 方式的请求	
		url += "source=" + 'pad' + "&token=" + localStorage.getItem('login.token');
	}

  if (isUpload) {
		data = options.data;
	} else {
		data = qs.stringify(options.data);
	}
  console.log(data)
  const newOptions = {
    url,
    method,
    timeout: 20000,
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  if(!noLoad){
    Toast.loading({
      duration: 0, // 持续展示 toast
      message: '加载中...',
      forbidClick: true,
    });
  }
  return axios.request(newOptions).then((response) => {
    if (!noLoad) {
      Toast.clear();
    }
    // 请求成功
    if (response.data.Code === 0) {
      return response.data;
    } else if (response.data.Code === 1001) {
      // 未登录
      Toast.fail({
        message: response.data.Message,
        duration: '1500',
        onClose() {
          router.push('/login')
        }
      });
			return;
    } else {
      Toast.fail(response.data.Message);
      return;
    }
  }).catch((err) => {
    if (!noLoad) {
      Toast.clear();
    }
    Toast.fail('接口请求失败');
  });
}
<template>
  <Header />
  <router-view />
</template>

<script>

;
import Header from '@/components/Header.vue';
export default {
  components: {
    Header
  },
}
</script>
<style>
  /* 公共样式 */
  @import './assets/css/common.css';
  /* 字体 */
  @import './assets/css/iconfont.css';
</style>

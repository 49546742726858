import { createStore } from 'vuex'
import request from '@/utils/request'
import router from '@/router';
import { Toast } from 'vant';

export default createStore({
  state: {
		refreshTime: '' || localStorage.getItem('refresh_time')
  },
  mutations: {
		SET_REFRESHTIME: (state, time) => {
      state.refreshTime = time
    },
  },
  actions: {
		// 登录
		async login ({ commit }, data) {
      const _data = await request('/v1/Member/Account/Login', {
        method: "POST",
        data: {
          LoginId: data.LoginId,
          Password: data.Password,
        }
      });
			if (_data.Code == 0) {
				localStorage.setItem('login.token', _data.Data.AccessToken);
				Toast.success({
					message: '登录成功',
					duration: '1500',
					onClose() {
						router.push('/')
					}
				});
			}
    },

		async logout ({commit}) {
			const _data = await request('/V1/Member/Account/LoginOut', {
			  method: "POST",
				data: {
					
				}
			});
			if (_data.Code == 0) {
				localStorage.removeItem('login.token');
				Toast.success({
					message: '退出成功',
					duration: '1000',
					onClose() {
						router.replace("/login")
					}
				});
			}
		},

		SetRefreshTime({commit}, time) {
			return new Promise((resolve, reject) => {
        localStorage.setItem('refresh_time', time)
				commit('SET_REFRESHTIME', time)
        resolve()
      })
		}
  },
  modules: {
  }
})

import { createRouter, createWebHistory } from 'vue-router'
import { Dialog } from 'vant'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue'),
    meta: {
      title: '院前急救系统',
      requireAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
    meta: {
      title: '登录',
      requireAuth: false
    }
  },
  {
    path: '/setting',
    name: 'settingIndex',
    component: () => import('@/views/setting/index.vue'),
    meta: {
      title: '系统设置',
      requireAuth: true
    }
  },
  {
    path: '/setting/account',
    name: 'settingAccount',
    component: () => import('@/views/setting/account.vue'),
    meta: {
      title: '账户信息',
      requireAuth: true
    }
  },
  {
    path: '/setting/parameter',
    name: 'settingParameter',
    component: () => import('@/views/setting/parameter.vue'),
    meta: {
      title: '系统参数',
      requireAuth: true
    }
  },
	{
	  path: '/task/history',
	  name: 'taskHistory',
	  component: () => import('@/views/task/history/index.vue'),
	  meta: {
	    title: '历史任务',
	    requireAuth: true
	  }
	},
	{
	  path: '/task/history/detail',
	  name: 'taskHistoryDetail',
	  component: () => import('@/views/task/history/detail.vue'),
	  meta: {
	    title: '历史任务详情',
	    requireAuth: true
	  }
	},
	{
	  path: '/task/detail',
	  name: 'taskDetail',
	  component: () => import('@/views/task/detail.vue'),
	  meta: {
	    title: '任务详情',
	    requireAuth: true
	  }
	},
  {
	  path: '/task/map',
	  name: 'map',
	  component: () => import('@/views/task/map.vue'),
	  meta: {
	    title: '地图',
	    requireAuth: true
	  }
	},
	{
	  path: '/task/case/add',
	  name: 'taskCaseAdd',
	  component: () => import('@/views/task/case/add.vue'),
	  meta: {
	    title: '填写电子病历',
	    requireAuth: true
	  }
	},
	{
	  path: '/task/case/view',
	  name: 'taskCaseView',
	  component: () => import('@/views/task/case/view.vue'),
	  meta: {
	    title: '查看电子病历',
	    requireAuth: true
	  }
	}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // 判断是否需要登录
  if (to.meta.requireAuth) {
    let token = localStorage.getItem('login.token');
    if (token) {
      next()
    } else {
      Dialog.confirm({
        message: '您还未登录请先登录',
        showCancelButton: false,
      }) .then(() => {
        router.replace("/login")
      })
    }
  } else {
    next()
  }
})

export default router

<template>
  <div class="header flex f-center" v-show="isShow">
    <div class="container flex f-center">
      <img class="logo" src="../assets/img/logo2.png" />
      <div class="header-title c-fff fon-14 font-bold ma-l-20">院前急救管理系统</div>
    </div>
  </div> 
</template>

<script>
let _this;
export default {
  data() {
    return {
      isShow: false,
    }
  },
	created() {
		_this = this;
	},
  watch:{
    $route(e){
      if(e.name != 'login'){
        _this.isShow = true
      }
    }
  },
}
</script>

<style lang="less" scoped>
  .header{
    width: 100%;
		height: 55px;
    background: #1A70B8;
		.header-title{
			font-size: 20px;
		}
		.logo{
			width: 130px;
		}
  }
</style>
<template>
	<div class="flex f-between f-center">
		<div class="title font-bold">
			<div class="fon-26 flex f-between">
				<div>{{detail.CarLicense}} - {{detail.StationName}}</div>
			</div>
			<div class="fon-18">车辆状态：{{detail.Status}}</div>
		</div>
		<div class="flex" v-if="show == 'true' && !isMap">
			<div class="btn-hospital c-fff flex f-center f-jcenter ma-r-5" v-if="taskStates == '未处理' || taskStates == '确认出车' || taskStates == '到达现场' || taskStates == '离开现场' || taskStates == '到达医院'">
				<text @click="arrivals(1,'确认出车')" v-if="taskStates == '未处理'">确认出车</text>
				<text @click="arrivals(2,'到达现场')" v-if="taskStates == '确认出车'">到达现场</text>
				<text @click="arrivals(3,'离开现场')" v-if="taskStates == '到达现场'">离开现场</text>
				<text @click="arrivals(4,'到达医院')" v-if="taskStates == '离开现场'">到达医院</text>
				<text @click="arrivals(5,'任务完成')" v-if="taskStates == '到达医院'">任务完成</text>
			</div>
			<!-- <a :href="'tel:' + phone.AlarmPhone">
				<div class="btn-call c-fff flex f-center f-jcenter ma-r-5">
					<span class="iconfont icon-xingzhuang ma-r-5"></span>报警人
				</div>
			</a>
			<a :href="'tel:' + phone.HospitalPhone">
				<div class="btn-call c-fff flex f-center f-jcenter ma-r-5">
					<span class="iconfont icon-xingzhuang ma-r-5"></span>医院
				</div>
			</a>
			<a :href="'tel:' + phone.ControlCenter">
				<div class="btn-call c-fff flex f-center f-jcenter ma-r-5">
					<span class="iconfont icon-xingzhuang ma-r-5"></span>主中心
				</div>
			</a>
			<a :href="'tel:' + phone.StationPhone">
				<div class="btn-call c-fff flex f-center f-jcenter">
					<span class="iconfont icon-xingzhuang ma-r-5"></span>救护站
				</div>
			</a> -->
		</div>
		<slot name="refresh"></slot>
	</div>
</template>

<script>
	let _this;
	export default {
		props: ['show','phone','taskId','taskStates','isMap'],
		data() {
			return {
				detail: '',
			}
		},
		created() {
			_this = this;
			_this.loadData();
		},
		methods:{
			
			// 加载数据
			async loadData(){
				let _data = await _this.$request('/Ambulance/v1/Status',{
					method: 'POST',
					data: {}
				});
				if (_data.Code == 0) {
					_this.detail = _data.Data;
				}
			},
			
			// 到达医院
			arrivals(type,name) {
				_this.$dialog.confirm({
				  title: '提示',
				  message: '确认' + name + '吗?',
				})
				.then(async () => {
					let _data = await _this.$request('/Task/v1/TaskTask/UpdateTime',{
						method: 'POST',
						data: {
							TaskId: _this.taskId,
							Type: type,
						}
					});
					if (_data.Code == 0) {
						if (type != 5) {
							_this.$toast.success({
								message: '设置成功',
								duration: '1000',
								onClose() {
									// 重新加载数据
									_this.loadData();
									_this.$parent.loadData();
								}
							})
						} else {
							_this.submit()
						}
					}
				})
				.catch(() => {
					// on cancel
				});
			},

			// 确认任务
			submit() {
				_this.$confirm('单次任务完成，请选择后续操作', '提示', {
          confirmButtonText: '继续接其他救护任务',
          cancelButtonText: '休整暂不接新任务',
          center: true,
					confirmButtonClass: 'confirm-button',
        }).then(() => {
          // 继续接其他救护任务
					_this.$request("/Ambulance/v1/UpdateStatus", {
						method: "POST",
						data: {
							TaskId: _this.$parent.id,
							Status: 1,
							Remark: '',
						},
					})
					.then((res) => {
						if (res.Code == 0) {
							_this.$toast.success({
								message: "设置成功",
								duration: "1000",
								onClose() {
									_this.loadData();
									_this.$parent.loadData();
								},
							});
						}
					});
        }).catch(() => {
					// 休整暂不接新任务
					_this.$request("/Ambulance/v1/UpdateStatus", {
						method: "POST",
						data: {
							TaskId: _this.$parent.id,
							Status: 2,
							Remark: '',
						},
					})
					.then((res) => {
						if (res.Code == 0) {
							_this.$toast.success({
								message: "设置成功",
								duration: "1000",
								onClose() {
									_this.loadData();
									_this.$parent.loadData();
								},
							});
						}
					});
          
        });
			}
		}
	}
</script>

<style lang="less" scoped>
	.title {
		color: #6D7278;
	}
	.c-red{
		color: #DA6D58;
		font-size: 27px;
	}
	.ma-r-5{
		margin-right: 5px;
	}
	.btn-hospital{
		width: 90px;
    height: 40px;
    line-height: 35px;
    background: #DA6D58;
    border-radius: 4px;
    font-size: 18px;
	}
	.btn-call{
		width: 75px;
		height: 35px;
		line-height: 35px;
		background: #8CBD66;
		border-radius: 4px;
		font-size: 13px;
	}

	/deep/ .el-message-box__message p{
		font-size: 16px;
	}
	/deep/ .el-message-box__btns .el-button--small {
		font-size: 14px;
	}
</style>

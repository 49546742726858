<template>
	<div class="flex f-center">
		<div class="iconfont icon-fanhui fon-50" @click="back"></div>
		<div class="name">{{msg}}</div>
	</div>
</template>

<script>
let _this;
export default {
	props: ['msg'],
  data () {
    return {
    }
  },
  created(){
    _this = this;
  },
  methods:{
		back(){
			_this.$router.go(-1);
		},
  }
}
</script>

<style>
</style>

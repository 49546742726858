import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import request from './utils/request.js'
import validator from './utils/validator.js'
import util from './utils/util.js'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'

// 组件
import CarState from '@/components/CarState.vue'
import BackButton from '@/components/BackButton.vue'
// import VueAMap from 'vue-amap';



const app = createApp(App);

// 挂载全局
app.config.globalProperties.$request = request;
app.config.globalProperties.$validator = validator;
app.config.globalProperties.$util = util;

app.use(store).use(router).use(Vant).use(ElementPlus, { locale }).component('CarState', CarState).component('BackButton', BackButton).mount('#app');




// VueAMap.initAMapApiLoader({
//   key: '7139a27fab8c9ab16fdf4defabe7ea39',
//   plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder'],
//   v: '1.4.4',
//   uiVersion: '1.0'
// })

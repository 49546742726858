//主要写的是会经常使用到的数据验证

/**
 * 是否为空值，包括null、'' ，返回true表示是，反之不是
 * @param {Object} 需要验证的值
 */
 function isEmpty(value) {
	return (value == null || value == "" || value.length <= 0);
}

/**
 * 是否是邮箱，返回true表示是，反之不是
 * @param {Object} email
 */
function isEmail(email) {
	return RegExp(/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\-|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/).test(
		email);
}

/**
 * 是否是手机号码，返回true表示是，反之不是
 * @param {Object} mobile
 */
function isMobile(mobile) {
	return RegExp(/^1\d{10}$/).test(mobile);
}

/**
 * 是否为金额，返回true表示是，反之不是
 * @param {Object} value
 */
function isMoney(value) {
	//000 错、、0 对、0. 错、0.0 对、050 错、00050.12错、70.1 对、70.11 对、70.111错、500 正确
	return RegExp( /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/).test(value);
}

/**
 * 精确判断数据是否是 Object 类型
 * @param {Any} val 要判断的数据
 * @returns {Boolean} true：是；false：不是；
 */
function isObject(val) {
	return Object.prototype.toString.call(val) === '[object Object]' && val !== null && val !== undefined;
}

/**
 * 判断数据是否是 Array 类型
 * @param {Any} val 要判断的数据
 * @returns {Boolean} true：是；false：不是；
 */
function isArray(val) {
	return Object.prototype.toString.call(val) === '[object Array]';
}

/**
 * 判断数据是否是 String 类型
 * @param {Any} val 要判断的数据
 * @returns {Boolean} true：是；false：不是；
 */
function isString(val) {
	return Object.prototype.toString.call(val) === '[object String]';
}

/**
 * 精确判断数据是否是 Date 类型
 * @param {Any} val 要判断的数据
 * @returns {Boolean} true：是；false：不是；
 */
function isDate(val) {
	return Object.prototype.toString.call(val) === '[object Date]';
}

/**
 * 精确判断数据是否是 Function 类型
 * @param {Any} val 要判断的数据
 * @returns {Boolean} true：是；false：不是；
 */
function isFunction(val) {
	return Object.prototype.toString.call(val) === '[object Function]';
}

/**
 * 精确判断数据是否是 Number 类型
 * @param {Any} val 要判断的数据
 * @returns {Boolean} true：是；false：不是；
 */
function isNumber(val) {
	return Object.prototype.toString.call(val) === '[object Number]';
}

/**
 * 精确判断数据是否是 Boolean 类型
 * @param {Any} val 要判断的数据
 * @returns {Boolean} true：是；false：不是；
 */
function isBoolean(val) {
	return Object.prototype.toString.call(val) === '[object Boolean]';
}

/**
 * 判断 URL 是否是绝对 URL。
 * @param {String} url 要判断的 URL
 * @return {Boolean} true：是绝对URL；false：不是绝对URL；
 */
function isAbsoluteURL(url) {
	// 如果 URL 以 “<scheme>：//” 或 “//”（协议相对URL）开头，则认为它是绝对的
	// RFC 3986 将方案名称定义为以字母开头的字符序列，然后是字母，数字，加号，句点或连字符的任意组合
	return /^([a-z][a-z\d\+\-\.]*:)?\/\//i.test(url);
}


/**
 * 是否为短信或邮件验证码
 * @param {String} value 要判断的值
 * @param {String} length 要验证的长度，默认6位
 * @return {Boolean} true：是正确验证码；false：不是正确验证码
 */
function isCode(value,length=6)
{
	 return  (!isEmpty(value) && value.length == length)
}


module.exports = {
	isEmpty: isEmpty,
	isEmail: isEmail,
	isMoney:isMoney,
	isMobile: isMobile,
	isObject: isObject,
	isArray: isArray,
	isString: isString,
	isDate: isDate,
	isFunction: isFunction,
	isNumber: isNumber,
	isBoolean: isBoolean,
	isAbsoluteURL: isAbsoluteURL,
	isCode:isCode,

}
